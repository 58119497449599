import { useEffect, useState } from "react";
import "./App.css";

const App = () => {
  const launchDate = new Date("Sept 1, 2023 00:00:00").getTime();

  const [days, setDay] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    // create a interval and get the id
    const myInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = launchDate - now;

      setDay(Math.floor(distance / (1000 * 60 * 60 * 24)));
      setHours(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      );
      setMins(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
    }, 1000);

    // clear out the interval using the id when unmounting the component
    return () => clearInterval(myInterval);
  }, [launchDate]);

  return (
    <section className="landing">
      <div className="landing-inner">
        <h1>Coming Soon ...</h1>
        <div className="countdown">
          <div>
            {days}
            <span>Days</span>
          </div>
          <div>
            {hours}
            <span>Hours</span>
          </div>
          <div>
            {mins}
            <span>Minutes</span>
          </div>
          <div>
            {seconds}
            <span>Seconds</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default App;
